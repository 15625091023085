import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Property } from '../interfaces';
import CdpClient from '../client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { IconPencil, IconPlus, IconTrash } from '@/components/Icons';


interface PropertyPageProps {
    cdpClient: CdpClient;
}

const PropertiesPage: React.FC<PropertyPageProps> = ({ cdpClient }) => {

    //const [selectedPropertyState, setSelectedPropertyState] = useState<number>(-2);
    const [properties, setProperties] = useState<Property[]>([]);
    const once = useRef(false);
    useEffect(() => {
        if (!once.current) {
            cdpClient.getProperties((response: any) => {
                setProperties(response || []);
                once.current = true;
            });
        }
    });
    return (
    <div className='container'>
        <div className="level toolbar">
            <div className="level-left">
                <div className="level-item">
                <h2 className="title">Properties</h2>
                </div>
            </div>
            <div className="level-right">
                <div className="level-item">
                <Link to='/admin/property-specs/new' className='button is-primary'>
                <IconPlus />&nbsp; Add property</Link>
                </div>
            </div>
        </div>
        <div className="box has-text-left">
            <table className='table is-fullwidth is-striped is-dark is-hoverable'>
                <thead>
                    <tr>
                        <th>Property name</th>
                        <th>Value type</th>
                        <th>ID type</th>
                        <th className="has-text-centered">ID merge</th>
                        <th className="has-text-centered">Protected</th>
                        <th className="has-text-centered">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {properties.map((property: Property, idx: number) => {
                        return (
                            <tr key={idx}>
                                <td>{property.label}</td>
                                <td>{property.value_type}</td>
                                <td>{property.identifier_type}</td>
                                <td className="has-text-centered">{property.identifier_merge ? <FontAwesomeIcon icon={faCheck} /> : ''}</td>
                                <td className="has-text-centered">{property.protected ? <FontAwesomeIcon icon={faCheck} /> : ''}</td>
                                <td width="100">
                                    <div className="field has-addons">
                                        <p className="control">
                                            <Link to={`/admin/property-specs/${property.id}`}>
                                                <span className="button"><IconPencil /></span>
                                            </Link>
                                        </p>
                                        <p className="control">
                                            <button
                                                className="button has-text-danger"
                                                onClick={() => {
                                                    cdpClient.deleteProperty(property, () => {
                                                        cdpClient.getProperties((response: any) => {
                                                            setProperties(response || []);
                                                        });
                                                    });
                                                }}
                                            >
                                                <IconTrash />
                                            </button>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div >
    </div>
    )
}

export { PropertiesPage };

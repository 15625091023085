import { FC, useRef, useEffect, useState } from 'react';
import LineChart from '@/components/LineChart';
import BarChart from '@/components/BarChart';
import { formats } from '@/components/Date';
import { format } from 'path';
interface DashboardProps {
    argoo: any;
}

const processEventStats = (eventStats: any[]) => {
    return eventStats.map((eventStat: any) => {
        const base: any = {
            name: formats.dateShort(eventStat.day),
            tot: eventStat.total,
        };
        for (let i in eventStat.stats) {
            base[i] = eventStat.stats[i];
        }
        console.log('base', base);
        return base;
    });
};
export const Dashboard: FC<DashboardProps> = (props: DashboardProps) => {
    const once = useRef(false);
    const [eventDayStats, setEventDayStats] = useState<any[]>([]);
    const [eventDayStatBars, setEventDayStatBars] = useState<string[]>([]);
    const [userStats, setUserStats] = useState<any[]>([]);
    const [segmentStats, setSegmentStats] = useState<any[]>([]);
    const [segmentStatsLines, setSegmentStatsLines] = useState<string[]>([]);
    useEffect(() => {
        if (!once.current) {
            props.argoo.cdpClient.getStats('event', {}, (response: any) => {
                const dayStats = processEventStats(response.days);
                setEventDayStats(dayStats);
                // const formatted_types = response.types.map((type: string) => {
                //     return formats.dateShort(type);
                // });
                setEventDayStatBars(response.types);
            });

            props.argoo.cdpClient.getStats('user', {}, (response: any) => {
                const formatted = response.days.map((entry: any) => {
                    const formatted_date = formats.dateShort(entry.day + 'T00:00:00');
                    console.log('entry', formatted_date, entry);
                    return {
                        day: formatted_date,
                        total: entry.total,
                    };
                });
                console.log('formatted', formatted, response);
                setUserStats(formatted);
            });
            props.argoo.cdpClient.getStats('segment', {}, (response: any) => {
                const entries = response.days.map((entry: any) => {
                    return {
                        name: entry.day,
                        total: entry.total,
                        ...entry.stats,
                    };
                });
                setSegmentStats(entries);
                setSegmentStatsLines(response.types);
            });
            once.current = true;
        }
    }, [props.argoo.cdpClient]);


    return (
        <div className='container'>
               <div className="level toolbar">
            <div className="level-left">
                <div className="level-item">
                <h2 className="title">CDP Status</h2>
                </div>
            </div>
            <div className="level-right">
                <div className="level-item">
                
                </div>
            </div>
            </div>
            <div className='columns'>
                <div className='column'>
                    <div className='box'>
                        <h3 className='subtitle is-4'>New profiles per day</h3>
                        <LineChart
                            data={userStats}
                            lines={['total']}
                        />
                    </div>
                </div>
                <div className='column'>
                    <div className='box'>
                        <h3 className='subtitle is-4'>Number of profiles in segments</h3>
                        <BarChart
                            data={segmentStats}
                            bars={segmentStatsLines}
                        />
                    </div>
                </div>
            </div>
            <div className='columns'>

                <div className='column'>
                    <div className='box'>
                        <h3 className='subtitle is-4'>Events per day</h3>
                        <BarChart
                            data={eventDayStats}
                            bars={eventDayStatBars}
                            stackId='a'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

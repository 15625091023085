import { useEffect, useState, useRef } from 'react';
import { SegmentSpec } from '@/interfaces';

import { Link } from 'react-router-dom';
import { IconPencil, IconPlus, IconTrash, IconEye } from '@/components/Icons';

interface SegmentsSpecPageProps {
    cdpClient: any;
}

export const SegmentsSpecPage: React.FC<SegmentsSpecPageProps> = ({ cdpClient }) => {
    const once = useRef(false);
    const [segments, setSegments] = useState<SegmentSpec[]>([]);
    const [stats, setStats] = useState<any[]>([]);
    useEffect(() => {
        if (once.current) {
            return;
        }
        cdpClient.getSegments((response: any) => {
            setSegments(response || []);
        });
        cdpClient.getStats('segment', {}, (response: any) => {
            console.log('segment stats', response);
            setStats(response.days || []);
        });
        once.current = true;
    });

    return (
        <div className='container'>
            <div className="level toolbar">
            <div className="level-left">
                <div className="level-item">
                <h2 className="title">Segments</h2>
                </div>
            </div>
            <div className="level-right">
                <div className="level-item">
                <Link to='/admin/segment-specs/new' className='button is-primary'>
                <IconPlus />&nbsp; Add segment</Link>
                </div>
            </div>
            </div>
            
            <div className='box'>
                <table className='table is-fullwidth is-striped is-hoverable'>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Webhooks</th>
                            <th className="has-text-right">Members</th>
                            <th className="has-text-centered">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {segments.map((segment: SegmentSpec, idx: number) => {
                            let count = 0;
                            stats.forEach((stat: any) => {
                                if (stat.day === segment.title) {
                                    count = stat.total;
                                }
                            });
                            return (
                                <tr key={idx}>
                                    <td>{segment.title}</td>
                                    <td>{segment.description}</td>
                                    <td>{(segment.webhooks?.length + segment.webhooks_leave?.length) || "-"}</td>
                                    <td className="has-text-right">{count}</td>
                                    <td width="100"> 
                                        <div className="field has-addons">
                                            <p className="control">
                                                <Link to={`/admin/segment/${segment.id}`}><span className="button"><IconEye /></span></Link>
                                            </p>
                                            <p className="control">
                                                <Link to={`/admin/segment-specs/${segment.id}`}><span className="button"><IconPencil /></span></Link>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

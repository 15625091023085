import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import logo from '@/logo.png';
import { IconAction, IconDashboard, IconEvents, IconProperties, IconSegments, IconSettings, IconUser } from '@/components/Icons';

interface LayoutProps {
    logout: () => void;
}

export const Layout: React.FC<LayoutProps> = ({ logout }: LayoutProps) => {
    const location = useLocation();
    const path = location.pathname;
    return (
        <div data-theme="dark">
            <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/admin">
                        <img src={logo} alt="arrigoo.io Logo" />
                    </a>
                </div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <Link to="/admin/account" className="navbar-item"><IconSettings /></Link>
                            <button className="" onClick={logout}>Log out</button>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className='menu-sidebar'>
                    <aside className="menu-sidebar-inner is-dark menu ar-nav" role="navigation" aria-label="main navigation">
                        <ul className="menu-list">
                            <li className="mb-2">
                                <Link to="/admin" className={"navbar-item " + (path=='/admin' ? 'has-text-primary' : '')}>
                                    <IconDashboard /><span className={(path=='/admin' ? 'has-text-light' : '')}>Dashboard</span>
                                </Link>

                            </li>
                            <li className="mb-2">
                                <Link to="/admin/profiles" className={"navbar-item " + (path.match('^/admin/profiles') ? 'has-text-primary' : '')}>
                                    <IconUser />
                                    <span className={(path.match('^/admin/profiles') ? 'has-text-light' : '')}>Profiles</span>
                                </Link>
                                  {path.match(/\/profiles/i) && (
                                <ul>
                                    <li><Link to="/admin/profiles/latestidentified" className={"sub-menu-item navbar-item " + (path=='/admin/profiles/latestidentified' ? 'has-text-light' : '')}>
                                    Latest identified</Link></li>
                                </ul>)}       

                                </li>
                            <li className="mb-2">
                                <Link to="/admin/segment-specs" className={"navbar-item " + (path.match('^/admin/segment') ? 'has-text-primary' : '')}>
                                <IconSegments /><span className={(path.match('^/admin/segment') ? 'has-text-light' : '')}>Segments</span></Link></li>

                            <li className="mb-2">
                                <Link to="/admin/event-specs" className={"navbar-item " + (path.match('^/admin/event') ? 'has-text-primary' : '')}>
                                <IconEvents /><span className={(path.match('^/admin/event') ? 'has-text-light' : '')}>Events</span></Link>
                                {path.match(/\/event/i) && (
                                <ul>
                                    <li><Link to="/admin/event-log" className={"navbar-item sub-menu-item " + (path=='/admin/event-specs' ? 'has-text-light' : '')}>
                                    Event log</Link></li>
                                </ul>)}
                            </li>
                            <li className="mb-2">
                                <Link to="/admin/property-specs" className={"navbar-item " + (path.match('^/admin/property') ? 'has-text-primary' : '')}>
                                    <IconProperties /><span className={(path.match('^/admin/property') ? 'has-text-light' : '')}>Properties</span>
                                </Link>
                            </li>
                            <li className="mb-2">
                                <Link to="/admin/actions" className={"navbar-item " + (path.match('^/admin/actions') ? 'has-text-primary' : '')}>
                                    <IconAction /><span className={(path.match('^/admin/actions') ? 'has-text-light' : '')}>Actions</span>
                                </Link>
                            </li>
                        </ul>
                    </aside>
            </nav>
            <div className="content-main">
                <div className="section">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

import { FC, useEffect, useState } from "react";
import { EventSpec, Action } from "@/interfaces";
import { EventSpecForm } from "@/forms/EventSpecForm";
import { IconPlus, IconTrash, IconPencil } from "@/components/Icons";
import Client from "@/client";

interface EventSpecPageProps {
    cdpClient: Client;
}
const parseSpecValueToDisplay = (setting: number) => {
    if (setting === 0) {
        return 'allowed';
    }
    if (setting === 1) {
        return 'required';
    }
    return 'disallowed';
}

export const EventSpecPage: FC<EventSpecPageProps> = ({ cdpClient }) => {
    const [eventSpecsState, setEventSpecs] = useState<EventSpec[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<number>(-1);
    const [actions, setActions] = useState<Action[]>([]);
    useEffect(() => {
        cdpClient.getActions((actions: any) => {
            cdpClient.getEventSpecs((response: any) => {
                console.log(response);
                setEventSpecs(
                    Object.values(response).map(
                        (spec: any) => {
                            spec.oldEvt = spec.evt;
                            return spec;
                        }
                    )
                );
            });
        });
    }, [cdpClient]);

    return (
        <div className='container'>
             <div className="level">
            <div className="level-left">
                <div className="level-item">
                <h2 className="title">Event types</h2>
                </div>
            </div>
            <div className="level-right">
                <div className="level-item">
                <button onClick={() => setSelectedEvent(-2)} className='button is-primary'>
                <IconPlus />&nbsp; Add event type</button>
                </div>
            </div>
        </div>
        <div className="box has-text-left">
            <table className="table is-fullwidth is-striped">
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Topics</th>
                        <th>String</th>
                        <th>Int</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {selectedEvent === -2 ?

                        <EventSpecForm
                            eventSpec={{
                                evt: '',
                                topics: 0,
                                strval: 0,
                                intval: 0,
                            }}
                            actions={actions}
                            onSave={(spec: EventSpec) => {
                                spec.topics = parseInt(spec.topics + "");
                                spec.strval = parseInt(spec.strval + "");
                                spec.intval = parseInt(spec.intval + "");
                                cdpClient.saveNewEventSpec(spec, (response: any) => {
                                    eventSpecsState.push(spec);
                                    setEventSpecs(eventSpecsState);
                                    console.log(response);
                                    setSelectedEvent(-1);
                                });
                            }}
                            onClose={() => { setSelectedEvent(-1) }}
                        />
                        : <></>
                    }

                    {eventSpecsState.map((eventSpec: EventSpec, idx: number) => {
                        const isSelected = (selectedEvent === idx);
                        return (
                            <>
                                {!isSelected ?
                                    <tr key={idx}>
                                        <td>{eventSpec.evt}</td>
                                        <td>{parseSpecValueToDisplay(eventSpec.topics)}</td>
                                        <td>{parseSpecValueToDisplay(eventSpec.strval)}</td>
                                        <td>{parseSpecValueToDisplay(eventSpec.intval)}</td>
                                        <td>
                                            <div className="buttons is-right">
                                                <button className="button" onClick={() => setSelectedEvent(idx)}>
                                                    <IconPencil />
                                                </button>
                                                <button
                                                    className="has-text-danger"
                                                    onClick={() => {
                                                        cdpClient.deleteEventSpec(eventSpec.evt, (response: any) => {
                                                            cdpClient.getEventSpecs((response: any) => {
                                                                setEventSpecs(
                                                                    Object.values(response).map(
                                                                        (spec: any) => {
                                                                            spec.oldEvt = spec.evt;
                                                                            return spec;
                                                                        }
                                                                    )
                                                                );
                                                            });
                                                        });
                                                    }}
                                                >
                                                    <IconTrash />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <EventSpecForm
                                        eventSpec={eventSpec}
                                        onSave={(spec: EventSpec) => {
                                            console.log(spec);
                                            spec.oldEvt = eventSpec.evt;
                                            spec.topics = parseInt(spec.topics + "");
                                            spec.strval = parseInt(spec.strval + "");
                                            spec.intval = parseInt(spec.intval + "");
                                            eventSpecsState[idx] = spec;
                                            setEventSpecs(eventSpecsState);
                                            cdpClient.saveEventSpec(spec, (response: any) => {
                                                cdpClient.getEventSpecs((response: any) => {
                                                    console.log('postsave', response);
                                                    const updated = Object.values(response).map(
                                                        (spec: any) => {
                                                            spec.oldEvt = spec.evt;
                                                            return spec;
                                                        }
                                                    )
                                                    setEventSpecs(updated);
                                                });
                                                setSelectedEvent(-1);
                                            });
                                        }}
                                        onClose={() => setSelectedEvent(-1)}
                                    />
                                }
                            </>

                        )
                    })}
                </tbody>
            </table>
        </div>
    </div>
    );
};

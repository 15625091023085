import { FC, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Action, ActionTrigger } from "@/interfaces";
import PageProps from "./PageProps";
import { IconEye, IconEnvelope, IconAction, IconTrash, IconActionWebhook, IconPencil, IconPlus } from "@/components/Icons";

const triggerClass = (triggerType: string): string => {
    switch (triggerType) {
        case 'enter':
            return 'has-text-primary';
        case 'leave':
            return 'has-text-warning';
        case 'event':
            return 'has-text-light';
        default:
            return 'has-text-info';
    }
}

export const ActionsPage: FC<PageProps> = ({ client }) => {
    const [actions, setActions] = useState<Action[]>([]);
    const once = useRef(false);

    useEffect(() => {
        if (!once.current) {
            client.getActions((response: any) => {
                setActions(response);
            });
            once.current = true;
        }
    }, [client]);

    return (<div className="container">
        <div className="level">
            <div className="level-left">
                <div className="level-item">
                    <h1 className="title">Actions</h1>
                </div>
            </div>
            <div className="level-right">
                <div className="level-item">
                    <Link to="/admin/actions/new" className="button is-primary"><IconPlus />&nbsp; Add action</Link>
                </div>
            </div>
        </div>
        <div>
            {actions.map((action: Action, idx: number) => {
                return (<div key={idx} className="box">
                    <div className="columns">
                        <div className="column is-1">
                            <div className="action-logo">
                                    {action.action_type === "webhook" ? <IconActionWebhook /> : <IconEnvelope />}
                            
                                </div>
                        </div>
                        <div className="column actioncolumnLeft is-3">
                            <p>{action.description}</p>
                        </div>
                        <div className="column actioncolumnLeft is-2">
                            <p className="tag is-rounded is-uppercase">{action.action_type}</p>
                        </div>
                        <div className="column actioncolumnLeft is-3">

                        </div>

                        <div className="column actioncolumnRight is-3">
                            <div className="field has-addons">
                              <p className="control">
                                <Link to={`/admin/actions/${action.aid}`}><button className="button is-solid">
                                  <span className="icon is-small">
                                    <IconEye />
                                  </span>
                                  <span>View</span>
                                </button></Link>
                              </p>
                              <p className="control">
                                <Link to={`/admin/actions/${action.aid}`}><button className="button is-solid"> 
                                  <span className="icon is-small">
                                    <IconPencil />
                                  </span>
                                  <span>Edit</span>
                                </button></Link>
                              </p>
                            </div>
                        </div>
                    </div>
                </div>)
            })
            }
        </div>
    </div>)
}

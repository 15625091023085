import { FC, useRef, useState, useEffect } from "react";
import { Action, ActionTrigger } from "@/interfaces";
import { useParams, useNavigate, Link } from "react-router-dom";
import CdpClient from "@/client";
import { IconClose } from "@/components/Icons";
import { ActionForm } from "@/forms/ActionForm";

interface ActionPageProps {
    client: CdpClient
}

export const ActionsFormPage: FC<ActionPageProps> = ({ client }) => {
    
    const { aid } = useParams<{ aid: string }>();
    const [action, setAction] = useState<Action | null>(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (aid === "new") {
            setAction({action_type: 'webhook', specs: {headers: {}, method: 'POST', url: ''} })
            return;
        }
        console.log('fetching action', aid);
        client.getActions((response: Action[]) => {
            const aidN = parseInt(aid + '');
            const action = response.find((action: Action) => action.aid === aidN);
            setAction(action as Action);
        });
    }, [client, aid]);

    return (
        <div className="container">
            <div className="toolbar">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">Edit action</h1>
                    </div>
                </div>
            </div>
            {!action && (<div>Loading...</div>)}
            {action && (
                <ActionForm action={action} onSave={(action) => {
                    delete action.triggers;
                    if (aid === "new") {
                        client.createAction(action); 
                    } else {
                        client.saveAction(action);
                    }
                    navigate('/admin/actions'); 
                }} />
            )}
        </div>
    );
};
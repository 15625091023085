import { FC, useState } from "react";
import { Action, EventSpec } from "@/interfaces";
import { EventSpecRadio } from "./EventSpecRadio";
import { IconClose, IconSave } from "@/components/Icons";

interface EventSpecFormProps {
    onSave: (spec: EventSpec) => void;
    onClose: () => void;
    eventSpec: EventSpec;
    actions?: Action[];
}



const EventSpecForm: FC<EventSpecFormProps> = ({
    onSave,
    onClose,
    eventSpec,
}: EventSpecFormProps) => {
    const [evtSpec, setEvtSpec] = useState(eventSpec);

    const handleEventSpecChange = (field: string, value: number) => {
        const updatedSpec: any = { ...evtSpec };
        updatedSpec[field] = value;
        setEvtSpec(updatedSpec);
    }

    return (<>
        <tr>
            <td>
                <div className="field">
                    <label className="label" htmlFor="evt">Event Types name:</label>
                    <input
                        type="text"
                        className="input"
                        defaultValue={eventSpec.evt}
                        name="evt"
                        onChange={(evt) => {
                            eventSpec.evt = evt.target.value;
                            setEvtSpec(eventSpec);
                        }}
                    />
                </div>
            </td>
            <td>
                <EventSpecRadio fieldName="topics" eventSpec={eventSpec} onChange={handleEventSpecChange} />
            </td>
            <td>
                <EventSpecRadio fieldName="strval" eventSpec={eventSpec} onChange={handleEventSpecChange} />
            </td>
            <td>
                <EventSpecRadio fieldName="intval" eventSpec={eventSpec} onChange={handleEventSpecChange} />
            </td>

            <td>
                <div className="buttons is-right">
                    <a
                        className="button is-primary"
                        onClick={() => onSave(evtSpec)}
                    > <IconSave /></a>

                    <a
                        className="button is-danger"
                        onClick={onClose} > <IconClose /> </a>
                </div>
            </td>
        </tr>
        <tr>
            <td colSpan={5}>
                <div className="field">
                    <label className="label" htmlFor="description">Description:</label>
                    <textarea
                        className="textarea"
                        rows={2}
                        defaultValue={eventSpec.description}
                        name="description"
                        onChange={(evt) => {
                            eventSpec.description = evt.target.value;
                            setEvtSpec(eventSpec);
                        }}
                    />
                </div>
            </td>
        </tr>
    </>
    )

};

export { EventSpecForm }
